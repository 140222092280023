<template>
    <div class="modal-profile-item-selector items-start">
        <div class="back-button-wrapper" @click="onClickClose">
            <i class="back-button material-icons">{{ options.icon || 'close' }} </i>
        </div>
        <div class="top">
            <div class="title" v-html="title" />
        </div>
        <div v-if="mode !== 'state'" class="input-wrapper items-center">
            <i class="material-icons i-search">search</i>
            <input ref="searchValue" v-model="searchValue" @keydown="onKeyDown" />
            <i v-if="searchValue" class="material-icons">cancel</i>
        </div>
        <div class="no-search-result m-l-20 flex-wrap" v-if="options.model !== 'state'">
            <i class="material-icons-outlined c-danger m-r-4">info</i>
            <span v-html="$translate('NO_SEARCH_RESULT')" />
        </div>
        <ul class="result flex-fill">
            <li
                @click="onClickItem(item)"
                class="item flex-row items-center flex-between"
                :key="item.id"
                v-for="item in profileItems"
            >
                <div v-html="item.name" />
                <i class="material-icons i-right">chevron_right</i>
            </li>
        </ul>
    </div>
</template>

<script>
import profileService from '@/services/profile'

export default {
    name: 'ModalProfileItemSelector',
    props: {
        options: Object,
    },
    data: () => ({
        profileItems: [],
        mode: 'state',
        submit: {
            state: '',
            subway: '',
        },
        searchValue: '',
    }),
    created() {
        this.init()
    },
    computed: {
        title() {
            if (this.mode === 'state') {
                return `<span class="c-primary">일하는 곳의 위치를 </span><span>알려주세요</span>`
            } else
                return `<span>일하는 곳과 가까운 </span><span class="c-primary">지하철역을 </span><p>선택해주세요</p>`
        },
        showDesc() {
            return this.options.model === 'state'
        },
    },
    methods: {
        async init() {
            const res = await profileService.regions()
            this.profileItems = this.profileItems.concat(res)
        },
        async onClickItem(item) {
            const me = this.$store.getters.me
            const { target } = event

            if (this.mode === 'state') {
                this.submit.state = item
                target.classList.add('selected')
                // 직장위치
                if (item.district === '지하철') {
                    setTimeout(async () => {
                        this.mode = 'subway'
                        this.profileItems = await profileService.stations({ region: item.name, name: '' })
                    }, 300)
                } else {
                    // 지하철 없는 지역은 office_region_id만 넣어서 API 요청
                    const payload = { office_state_id: this.submit.state.id, office_station_id: '' }
                    await profileService.update_office_region(me, payload)
                    this.$toast.success('직장 위치가 등록되었습니다.')
                    localStorage.setItem('state_subway', true)
                    this.$emit('close')
                }
            } else {
                // 지하철역
                this.submit.subway = item

                const idx = await this.$modal.basic({
                    title: '직장 위치 확인',
                    body: `${this.submit.state.name}(${this.submit.subway.name})`,
                    buttons: [
                        {
                            label: 'TODAYMEET_FIX',
                            class: 'btn-default',
                        },
                        {
                            label: 'TODAYMEET_SUBMIT',
                            class: 'btn-primary',
                        },
                    ],
                })
                if (idx === 1) {
                    const payload = { office_state_id: this.submit.state.id, office_station_id: this.submit.subway.id }
                    await profileService.update_office_region(me, payload)
                    localStorage.setItem('state_subway', true)
                    this.$toast.success('직장 위치가 등록되었습니다.')
                    this.$emit('close')
                }
            }
        },
        async onClickClose() {
            if (this.mode === 'state') {
                this.$emit('close')
            } else {
                this.mode = 'state'
                this.profileItems = []
                const res = await profileService.regions()
                this.profileItems = this.profileItems.concat(res)
            }
        },
        async onKeyDown() {
            setTimeout(() => {
                const region = this.submit.state.name
                profileService.stations({ region, name: this.searchValue }).then(res => {
                    this.profileItems = res
                })
            }, 200)
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-profile-item-selector {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.2px;
    color: $grey-08;

    @include modal-fullscreen;
    @include f-regular;

    .back-button-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 14px $header-left-padding 20px;
    }

    .back-button {
        font-size: 24px;
        color: $grey-08;
        margin: 0;
    }

    .top {
        padding: 0 20px;
        margin-bottom: 40px;

        .title {
            font-size: 24px;
            letter-spacing: -0.4px;
            color: black;

            @include f-medium;
        }

        .desc {
            margin-top: 12px;
            font-size: 16px;
            color: $grey-09;
        }
    }

    .result {
        width: 100%;
        overflow-y: auto;
    }

    .input-wrapper {
        display: flex;
        flex-direction: row;
        width: calc(100% - 40px);
        height: 52px;
        border-radius: 8px;
        border: 1px solid $grey-02;
        padding: 12px 16px;
        margin: 0 20px 12px;

        .material-icons {
            font-size: $icon-size;
            color: $grey-05;
            margin: 0;
        }

        .i-search {
            margin-right: 16px;
            color: $grey-08;
        }

        input {
            font-size: 15px;
            margin: 0;
            padding: 0;
            color: black;
        }

        input::placeholder {
            color: $grey-05;
        }
    }

    .no-search-result {
        color: $brand-danger;
        font-size: 13px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .material-icons-outlined {
            font-size: 14px;
        }
    }

    .item {
        padding: 14px 20px;
        font-size: 16px;
        line-height: 1.38;

        > div {
            max-width: 280px;
            @include lines-1;
        }

        &.selected {
            color: $blue-primary;
            @include f-bold;

            .i-right {
                color: $blue-primary;
            }
        }

        &:not(:last-child) {
            border-bottom: 1px solid $grey-01;
        }
    }

    .i-right {
        font-size: 24px;
        margin: 0;
        color: $grey-04;
    }

    .grid-col {
        margin: 0 20px;
        grid-gap: 12px;
        grid-auto-rows: 48px;
        border-top: none;
        width: initial;

        .item {
            border-radius: 8px;
            border: 1px solid $grey-02;
            width: calc((100vw - 12px - 40px) / 2);
            @include center;

            &.selected {
                border: 1px solid $blue-primary;
            }

            .i-right {
                display: none;
            }
        }
    }
}
</style>
